import { Dispatch } from "redux";

import { ActionType, ToastMessage } from "./model";

const setToastStatus = (msg: ToastMessage[], messageId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: ActionType.SET_TOAST,
    data: msg,
    messageId: messageId,
  });
};

const removeToastStatus = (arr: ToastMessage[]) => (dispatch: Dispatch) => {
  dispatch({
    type: ActionType.REMOVE_TOAST,
    data: arr,
  });
};

const setUnreadCount = (count: number) => (dispatch: Dispatch) => {
  dispatch({
    type: ActionType.SET_UNREAD_COUNT,
    count,
  });
};

const setSupportUnreadMessageBoolean = (state: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: ActionType.SET_UNREAD_SUPPORT_MESSAGE_BOOLEAN,
    data: state,
  });
};

export { setToastStatus, removeToastStatus, setUnreadCount, setSupportUnreadMessageBoolean };
