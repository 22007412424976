import { getUpcomingShiftsGrouped } from "@store/ongoingShifts/apiV2";
import { Dispatch } from "redux";

import { ActionType } from "./model";

export const setChannels = async (dispatch: Dispatch, channels: SendBird.GroupChannel[]) => {
  dispatch({
    type: ActionType.SET_CHANNELS,
    data: {
      channels,
    },
  });

  if (!channels.length) {
    return;
  }

  fetchAndSetUpcomingShifts(dispatch);
};

export const fetchAndSetUpcomingShifts = async (dispatch: Dispatch) => {
  const shiftsByFacility = await getUpcomingShiftsGrouped();

  dispatch({
    type: ActionType.SET_UPCOMING_SHIFTS,
    data: {
      shiftsByFacility,
    },
  });
};

export const updateChannel = async (dispatch: Dispatch, channel: SendBird.GroupChannel) => {
  dispatch({
    type: ActionType.UPDATE_CHANNEL,
    data: {
      channel,
    },
  });
};
