export const ActionType = {
  SET_TOAST: "SET_TOAST",
  REMOVE_TOAST: "REMOVE_TOAST",
  SET_UNREAD_COUNT: "SET_UNREAD_COUNT",
  SET_UNREAD_SUPPORT_MESSAGE_BOOLEAN: "SET_UNREAD_SUPPORT_MESSAGE_BOOLEAN",
};

export interface ToastMessage {
  msg: string;
  link: string;
  messageId: string;
  method: string;
}

export interface NotificationSettings {
  status: false;
  msg: ToastMessage[];
  messageId: string | null;
  unreadCount: number;
  hasUnreadSupportMessage: boolean;
}
