import "./signupBonusClaim.scss";

import { IonButton, IonCol, IonGrid, IonLabel, IonRow, IonText } from "@ionic/react";
import { useToast } from "@src/appV2/lib";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useUpdateWorkerCache } from "@src/appV2/Worker/useUpdateWorkerCache";
import { formatPhone } from "@src/lib/utils";
import { FC, Fragment } from "react";

import { claimBonus } from "./api";

const SignupBonusClaim: FC<{}> = () => {
  const worker = useDefinedWorker();
  const { showSuccessToast } = useToast();
  const updateWorkerCache = useUpdateWorkerCache();

  const collectBonus = () => {
    claimBonus();
    showSuccessToast("You have successfully collected your bonus.");
    updateWorkerCache({ hasClaimedBonus: true });
  };

  const { signupConfig = {} } = worker;

  return (
    <Fragment>
      <IonGrid class="grid-centered">
        <IonRow>
          <IonCol class="ion-margin-bottom">
            <img width="250" height="250" src="assets/logo/dollar-bunny.svg" alt="Clipboard logo" />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonText color="dark" class="custom-style-title">
              Woohoo! Thanks
              <IonLabel style={{ marginLeft: "2px" }}> {worker.name}</IonLabel>{" "}
            </IonText>
            <IonText style={{ display: "block", lineHeight: "1.2" }} color="text">
              we're thrilled you're joining us.{" "}
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol class="ion-margin-top">
            <IonLabel></IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol style={{ width: "320px" }}>
            <IonText color="text">
              Collect your
              <IonLabel>
                {" "}
                <b>${signupConfig.signingBonusAmount1} </b>
              </IonLabel>
              bonus (we'll send a Venmo request to
              <b style={{ margin: "0 4px 0 2px" }}>{formatPhone(String(worker.phone))}</b>) and
              start picking up shifts! You'll receive your second
              <b> ${signupConfig.signingBonusAmount2} </b>
              bonus after you
              <b> {signupConfig.eventName}</b>
            </IonText>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <IonButton class="ion-margin-top" size="large" color="primary" onClick={collectBonus}>
              Collect Bonus and Enter App
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </Fragment>
  );
};

export { SignupBonusClaim };
