import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import request from "superagent";

import { getAuthHeader } from "../superagent";

const claimBonus = async (): Promise<{}> => {
  return await request
    .post(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/claimBonus`)
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export { claimBonus };
