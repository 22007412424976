import { Preferences } from "@capacitor/preferences";
import { isDevelopmentNodeEnvironment } from "@src/appV2/environment";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import { rootReducer } from "./reducer";
export { useAppSelector } from "./useAppSelector";

const initialState = {};

const enhancers: any[] = [];
const middleware = [thunk.withExtraArgument({ Preferences })];

if (isDevelopmentNodeEnvironment()) {
  const devToolsExtension = window["__REDUX_DEVTOOLS_EXTENSION__"];

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension({ trace: true }));
  }
}

export const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

export const store = createStore(rootReducer, initialState, composedEnhancers);
