import { isDefined } from "@clipboard-health/util-ts";
import { forwardRef, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import SignaturePad from "react-signature-pad-wrapper";

export interface SignatureContainerRef {
  clearSignature: () => void;
}
export interface SignatureContainerProps {
  onChange: (signature: string) => void;
  onStrokeStarted?: () => void;
  getHeight?: () => number;
  signature?: string;
}

export const SignatureContainer = forwardRef<SignatureContainerRef, SignatureContainerProps>(
  ({ onChange, getHeight = () => NaN, signature, onStrokeStarted }, ref) => {
    const signaturePad = useRef<SignaturePad>(null);
    if (signature) {
      signaturePad?.current?.fromDataURL(signature);
    }
    const [current, setCurrent] = useState<SignaturePad | null>(signaturePad.current);

    useLayoutEffect(() => {
      setCurrent(signaturePad.current);
      if (current === null) {
        return;
      }
      /*
        Calling this to resize the signature pad on initial render correctly.
        When this is not present, signature pad has invalid size when in certain
        flex containers.
      */
      current.handleResize();
      const onEndStroke = () => {
        const signature = current?.toDataURL();

        if (isDefined(signature)) {
          onChange(signature);
        }
      };
      const onBeginStroke = () => {
        current.handleResize();
        onStrokeStarted?.();
      };

      current.instance.addEventListener("endStroke", onEndStroke);
      current.instance.addEventListener("beginStroke", onBeginStroke);

      return () => {
        current.instance.removeEventListener("endStroke", onEndStroke);
        current.instance.removeEventListener("beginStroke", onBeginStroke);
      };
    }, [current, onChange, onStrokeStarted]);
    useImperativeHandle(ref, () => ({
      clearSignature() {
        current?.clear();
      },
    }));

    const canvasHeight = getHeight();

    return canvasHeight ? (
      <div style={{ height: canvasHeight }}>
        <SignaturePad ref={signaturePad} redrawOnResize height={canvasHeight} />
      </div>
    ) : (
      <SignaturePad ref={signaturePad} redrawOnResize />
    );
  }
);

SignatureContainer.displayName = "SignatureContainer";
